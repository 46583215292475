/* You can add global styles to this file, and also import other style files */
// .upper {
//     .navbar {
//         font-family: Lato !important;
//         font-size: 15px !important;
//         font-weight: 300 !important;
//         padding: 0.25em 0 0.5em;
//         border-left: 1px solid #e7ecea;
//         -webkit-transition: color 0.2s;
//         transition: color 0.2s;
//         height: 58px !important;
//         background-repeat: no-repeat !important;
//         background-position: right !important;
//         background: -moz-linear-gradient(left, rgba(0,172,196,1) 0%, rgba(93,123,154,1) 100%); /* FF3.6+ */
//         background: -webkit-gradient(linear, left top, right top, color-stop(0%,rgba(0,172,196,1)), color-stop(100%,rgba(93,123,154,1))); /* Chrome,Safari4+ */
//         background: -webkit-linear-gradient(left, rgba(0,172,196,1) 0%,rgba(93,123,154,1) 100%); /* Chrome10+,Safari5.1+ */
//         background: -o-linear-gradient(left, rgba(0,172,196,1) 0%,rgba(93,123,154,1) 100%); /* Opera 11.10+ */
//         background: -ms-linear-gradient(left, rgba(0,172,196,1) 0%,rgba(93,123,154,1) 100%); /* IE10+ */
//         background: linear-gradient(to right, rgba(0,172,196,1) 0%,rgba(93,123,154,1) 100%); /* W3C */
//         filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#eaad20', endColorstr='#00ffffff',GradientType=1 );
//         margin-bottom: 0px !important;
//     }
//     /* Chrome, Safari, Opera and Edge */
//     ::-webkit-input-placeholder {
//         font-size: small;
//         font-style: italic;
//         color: gray;
//     }
//     /* IE10 and IE11 */
//     :-ms-input-placeholder {
//         font-size: small;
//         font-style: italic;
//         color: gray;
//     }

//     .navbar a {
//         color: white !important;
//         margin-right: 5px !important;
//     }

//     .navbar .nav a:active {
//         font-weight: bold;
//     }

//     #mod {
//         color: #222;
//         font-size: 13px;
//         float: right;
//         text-align: right;
//         padding-top: 5px;
//     }

//     .navbar-brand {
//         font-size: 32px !important;
//         padding-top: 8px;
//         padding-right: 0px !important;
//     }

//     .navbar li {
//         font-family: Raleway, Arial, sans-serif !important;
//         margin-bottom: -15px;
//         min-width: 50px !important;
//         overflow: auto !important;
//     }

//     .navbar-nav > li > a {
//         padding-left: 10px !important;
//         float: right;
//         padding-right: 20px !important;
//         margin-right: 0px !important;
//         text-align: center;
//     }

//     p.navbar-text {
//         color: white !important;
//     }

//     .navbar-nav ul {
//         min-width: 80px;
//         overflow: auto;
//     }

//     .navbar ul {
//         transition: height 2s;
//         -moz-transition: height 2s; /* Firefox 4 */
//         -webkit-transition: height 2s; /* Safari and Chrome */
//         -o-transition: height 2s; /* Opera */
//     }

//     .navbar-inverse .navbar-nav > li > a {
//         color: white !important;
//     }

//     @media (max-width: 767px) {
//         .navbar-inverse .navbar-nav > li > a {
//             color: white !important;
//         }

//         .navbar-inverse .navbar-nav > li > a:hover {
//             color: black !important;
//         }

//         .navbar-inverse .navbar-nav > li {
//             background-color: #5d7b9a !important;
//         }

//         #glyHome > img {
//             display: none;
//         }

//         #glyList > img {
//             display: none;
//         }

//         #glyPencil > img {
//             display: none;
//         }

//         #glyCog > img {
//             display: none;
//         }
//     }



//     @media (max-width: 1000px) {
//         #proLogo > img {
//             display: none;
//         }
//     }

//     .navbar-toggle > a {
//         color: white !important;
//     }

//     #glyHome, #glyList, #glyPencil, #glyCog {
//         margin-left: 3px;
//         margin-top: 15px;
//     }

//     .nav-pills > .active > a:hover, .nav-pills > li > a:hover {
//         text-decoration: none;
//         font-weight: bold;
//         border-radius: 0px;
//         border-bottom-width: 4px;
//         border-bottom-style: solid;
//         border-bottom-color: #FF6600;
//         color: #000 !important;
//         background-color: transparent !important;
//     }
// }

@font-face {
    font-family: 'Lato';
    src: url('/fonts/lato.eot');
    font-weight: normal;
    font-style: normal;
}

@font-face {
    font-family: 'FontAwesome';
    src: url('/fonts/fontawesome-webfont.eot?v=4.2.0');
    src: url('/fonts/fontawesome-webfont.eot?#iefix&v=4.2.0') format('embedded-opentype'), url('/fonts/fontawesome-webfont.woff?v=4.2.0') format('woff'), url('/fonts/fontawesome-webfont.ttf?v=4.2.0') format('truetype'), url('/fonts/fontawesome-webfont.svg?v=4.2.0#fontawesomeregular') format('svg');
    font-weight: normal;
    font-style: normal;
}

/* latin-ext */
@font-face {
    font-family: 'Lato';
    font-style: normal;
    font-weight: 400;
    src: local('Lato Regular'), local('Lato-Regular'), url(/fonts/UyBMtLsHKBKXelqf4x7VRQ.woff2) format('woff2');
    unicode-range: U+0100-024F, U+1E00-1EFF, U+20A0-20AB, U+20AD-20CF, U+2C60-2C7F, U+A720-A7FF;
}

/* latin */
@font-face {
    font-family: 'Lato';
    font-style: normal;
    font-weight: 400;
    src: local('Lato Regular'), local('Lato-Regular'), url(/fonts/1YwB1sO8YE1Lyjf12WNiUA.woff2) format('woff2');
    unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2212, U+2215, U+E0FF, U+EFFD, U+F000;
}

/* latin-ext */
@font-face {
    font-family: 'Lato';
    font-style: normal;
    font-weight: 700;
    src: local('Lato Bold'), local('Lato-Bold'), url(/fonts/ObQr5XYcoH0WBoUxiaYK3_Y6323mHUZFJMgTvxaG2iE.woff2) format('woff2');
    unicode-range: U+0100-024F, U+1E00-1EFF, U+20A0-20AB, U+20AD-20CF, U+2C60-2C7F, U+A720-A7FF;
}

/* latin */
@font-face {
    font-family: 'Lato';
    font-style: normal;
    font-weight: 700;
    src: local('Lato Bold'), local('Lato-Bold'), url(/fonts/H2DMvhDLycM56KNuAtbJYA.woff2) format('woff2');
    unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2212, U+2215, U+E0FF, U+EFFD, U+F000;
}

/* latin-ext */
@font-face {
    font-family: 'Merriweather Sans';
    font-style: normal;
    font-weight: 400;
    src: local('Merriweather Sans'), local('MerriweatherSans-Regular'), url(/fonts/AKu1CjQ4qnV8MUltkAX3sD48MEBspdEKklcQvcIk8pU.woff2) format('woff2');
    unicode-range: U+0100-024F, U+1E00-1EFF, U+20A0-20AB, U+20AD-20CF, U+2C60-2C7F, U+A720-A7FF;
}

/* latin */
@font-face {
    font-family: 'Merriweather Sans';
    font-style: normal;
    font-weight: 400;
    src: local('Merriweather Sans'), local('MerriweatherSans-Regular'), url(/fonts/AKu1CjQ4qnV8MUltkAX3sMZ4HySTEWshgORbPp2tk8k.woff2) format('woff2');
    unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2212, U+2215, U+E0FF, U+EFFD, U+F000;
}

a {
    color: #03A9F4;
}

.filterExport {
    background: url('/assets/sprite-ERA.png') no-repeat 5px -1233px;
}


.filterNew {
    background: url('/assets/icons/plus-sign-new.png') no-repeat;
    background-position: 5px 9px;
}

/* Chrome, Safari, Opera and Edge */
::-webkit-input-placeholder {
    font-size: small;
    font-style: italic;
    color: gray;
}

/* IE10 and IE11 */
:-ms-input-placeholder {
    font-size: small;
    font-style: italic;
    color: gray;
}

/*this is for the detail list*/
.detailWrap {
    width: 1200px;
    height: 100%;
    border: solid 0px red;
    margin-top: 0px;
}

.detailTab {
    float: left;
    margin-right: 30px;
    color: skyblue;
    font-weight: 500;
    text-decoration: underline;
    padding-left: 40px;
    white-space: nowrap;
    width: 15% !important;
}

.detailContent {
    margin-top: 50px;
    clear: both;
    height: 100px;
    border: solid 0px green;
}

#detailContracts {
    background: url('/assets/Sprite.png') no-repeat -116px -319px;
    width: 26px;
    height: 32px;
}

#detailMain {
    background: url('/assets/Sprite.png') no-repeat -117px -249px;
    width: 25px;
    height: 25px;
}

#detailOpportunity {
    background: url('/assets/Sprite.png') no-repeat -115px -204px;
    width: 27px;
    height: 34px;
}

#detailProjectSite {
    background: url('/assets/Sprite.png') no-repeat -114px -363px;
    width: 30px;
    height: 30px;
}

#detailAccount {
    background: url('/assets/Sprite.png') no-repeat -115px -169px;
    width: 30px;
    height: 24px;
}

#detailERA {
    background: url('/assets/Sprite.png') no-repeat -113px -286px;
    width: 31px;
    height: 20px;
}

#detailClientSite {
    background: url('/assets/Sprite.png') no-repeat -113px -286px;
    width: 31px;
    height: 20px;
}

.managePerm {
    background: url('/assets/icons/manage-permissions.png') no-repeat;
    background-position: 3px -1px;
}

.managePerm2 {
    width: 35px;
    height: 40px;
    display: inline-block;
    background: url('./assets/icons/permission.png') 0 0;
}

.favicon-home {
    margin-bottom: 6px !important;
    margin-top: 1px !important;
}

.favicon-engagement-a {
    padding-top: 10px !important;
}

.favicon-engagement {
    margin-top: 2px !important;
    margin-bottom: -2px !important;
}

.favicon-accounts {
    margin-top: 0px !important;
    margin-bottom: -1px !important;
}

.favicon-accounts-a {
    padding-top: 10px !important;
}

.favicon-collaboration {
    margin-top: -2px !important;
}

.favicon-collaboration-a {
    padding-top: 12px !important;
    padding-bottom: 4px !important;
}

.favicon-opportunities {
    margin-bottom: 0px !important;
    margin-top: -2px !important;
}

.favicon-opportunities-a {
    padding-top: 10px !important;
}

.favicon-DRB {
    margin-bottom: 5px !important;
    margin-top: -5px !important;
}

.favicon-DRB-a {
    padding-top: 10px !important;
}

.favicon-ERA {
    margin-bottom: 0px !important;
    margin-top: 0px !important;
}

.favicon-ERA-a {
    padding-top: 10px !important;
    margin-bottom: 0px !important;
    padding-bottom: 5px !important;
}

.favicon-PKIC {
    margin-top: -4px !important;
    margin-bottom: 1px !important;
}

.favicon-PKIC-a {
    padding-top: 10px !important;
}

.favicon-budgets {
    margin-bottom: -3px !important;
    margin-top: 0px !important;
}

.favicon-budgets-a {
    padding-top: 10px !important;
}

.favicon-PKIC {
    margin-top: -4px !important;
}

.favicon-PKIC-a {
    padding-top: 10px !important;
}

#diviManageAssistantLogo {
    margin-bottom: 0px !important;
}

/* Tooltip container */
div.tooltip {
    img.small-i {
        width: 20px;
    }

    div.icon-div {
        width: 80px;
        display: inline-block;
    }

    div.icon-div img {
        width: 55px;
        margin-left: 5px;
    }

    div.text-div {
        width: 600px;
        font-size: 17px;
        display: inline-block;
        vertical-align: top;
    }
}

.tooltip {
    position: relative;
    display: inline-block;
    opacity: 1;
}

/* Tooltip text */
.tooltip .tooltiptext {
    font-size: 17px;
    font-family: "Lato";
    border: 1px #ccc solid;
    visibility: hidden;
    width: 730px;
    background-color: #eee;
    color: #0f3e63;
    text-align: left;
    padding: 16px;
    border-radius: 6px;
    /* Position the tooltip text - see examples below! */
    position: absolute;
    z-index: 0 !important;
}

/* Show the tooltip text when you mouse over the tooltip container */

.tooltip:hover .tooltiptext {
    top: 20px;
    left: -20px;
    visibility: visible;
}

.popup {
    border: solid #ccc 1px;
    font-family: Lato;
    color: #222;
    position: absolute;
    width: 80%;
    background: #eee;
    z-index: 1001;
}

.textAreaStyle {
    width: 100%;
    height: 80px;
}

/* Tooltip container */
.tooltip {
    z-index: 600;
    position: relative;
    display: inline-block;
    opacity: 1;
}

/* Tooltip text */
.tooltip .tooltiptext {
    border: 1px #ccc solid;
    visibility: hidden;
    width: 500px;
    background-color: #eee;
    color: #5d7b9a;
    text-align: left;
    padding: 16px;
    border-radius: 6px;
    /* Position the tooltip text - see examples below! */
    position: absolute;
    z-index: 1;
}

/* Show the tooltip text when you mouse over the tooltip container */

.tooltip:hover .tooltiptext {
    visibility: visible;
}

.sprite-ERA_note {
    width: 50px;
    height: 53px;
    display: inline-block;
    background: url('./assets/sprite-ERA.png') 0 -921px;
}


.filterGarbage {
    background: url('./assets/icons/trash-icon.png') no-repeat;
    background-position: 5px 5px;
}

.sprite-ERA_setup1 {
    width: 35px;
    height: 40px;
    display: inline-block;
    background: url('./assets/sprite-ERA.png') 0 0;
}

legend {
    border-style: none;
    border-width: 0;
    color: #5d7b9a;
    font-weight: 400;
    font-size: 18px;
    line-height: 20px;
    margin-bottom: 0;
}

fieldset {
    -webkit-box-shadow: 0px 0px 0px 0px #000;
    box-shadow: 0px 0px 0px 0px #000;
    background: white !important;
}

.gradient {
    background: red;
    /* For browsers that do not support gradients */
    background: -webkit-linear-gradient(left, #03A9F4, #5d7b9a);
    /* For Safari 5.1 to 6.0 */
    background: -o-linear-gradient(right, #03A9F4, #5d7b9a);
    /* For Opera 11.1 to 12.0 */
    background: -moz-linear-gradient(right, #03A9F4, #5d7b9a);
    /* For Firefox 3.6 to 15 */
    background: linear-gradient(to right, #03A9F4, #5d7b9a);
    /* Standard syntax (must be last) */
}

.gradient a {
    color: white;
}

h2 {
    color: #f68720;
    font-size: 20px;
    margin-top: 10px;
    margin-bottom: 2px;
    display: inline-block;
    vertical-align: top;
}

.backIcon {
    background: url('./assets/icons/go-back.png') no-repeat 10px 10px;
}

.btnIcon {
    color: #000 !important;
    background-color: #fff !important;
    border: 1px solid #ccc !important;
    border: 1px solid #337ab7 !important;
    border-radius: 4px;
    display: inline-block;
    outline: 0;
    padding: 0;
    vertical-align: middle;
    overflow: hidden;
    text-decoration: none;
    color: inherit;
    background-color: inherit;
    text-align: center;
    cursor: pointer;
    white-space: nowrap;
    margin-left: 5px;
    font-family: Lato !important;
}

.navbar-inverse {
    background: linear-gradient(to right, #00acc4 0%, #5d7b9a 100%);
}

.sprite_iManageAssistantLogo2 {
    margin-top: -5px;
    width: 60px;
    height: 32px;
    display: inline-block;
    background: url('./assets/Sprite-6fab4d.png') 0 -50px;
}

.sprite_iManageAssistantLogo3 {
    width: 150px;
    height: 54px;
    display: inline-block;
    background: url('./assets/icons/imanageAssistant.png') no-repeat;
}


html {
    position: relative;
    min-height: 100%;
}



body {
    background: #efefef;
    position: relative;
    padding-bottom: 50px;
    font-family: "Helvetica", sans-serif;
}

footer {
    left: 0;
    bottom: 0;
    width: 100%;
    overflow: hidden;
    height: 50px;
}


.tileBox {
    cursor: pointer;
}

.tileBox:hover {
    -webkit-border-radius: 10px;
    -moz-border-radius: 10px;
    border-radius: 10px;
    -webkit-box-shadow: 0px 0px 30px 0px rgba(0, 255, 0, 0.67) !important;
    -moz-box-shadow: 0px 0px 30px 0px rgba(0, 255, 0, 0.67) !important;
    box-shadow: 0px 0px 30px 0px rgba(0, 255, 0, 0.67) !important;
}

/**  ACCOUNT FORM */
@media (min-width: 1280px) {
    .component {
        width: 1280px;
        margin-left: auto;
        margin-right: auto;
    }
}

h2 {
    color: orange;
    font-size: 20px;
    margin-top: 10px;
    margin-bottom: 2px;
    display: inline-block;
    vertical-align: top;
}

hr {
    margin-top: 2px;
    margin-bottom: 10px;
}

a {
    color: #03A9F4;
}

.component {
    border: solid #5d7b9a 1px;
    border-top: solid #f68720 2px;
    padding: 13px;
    background: white;
    margin-top: 6px;
}

.content-section {
    border: solid #5d7b9a 1px;
    border-top: solid #f68720 2px;
    padding: 13px;
    background: white;
    margin-top: 6px;
}

.bold {
    font-weight: 600;
}

div.right {
    text-align: right;
}

div.center {
    text-align: center;
}

.form-control {
    font-family: "Helvetica", sans-serif;
}

div.blulabel {
    color: #0f3e63;
    font-family: "Helvetica", sans-serif;
}

/* *  ERA Setup Form validation */
.errorClass {
    border-color: #DF0101;
}

.labelErrorClass {
    color: #DF0101;
}

div.asterix {
    font-size: 28px;
    color: orange;
    width: 6px;
    display: inline-block;
}

.white_asterix {
    color: white;
    display: inline-block;
}

.red_asterix {
    color: #DF0101;
}

div.labelText {
    color: #0f3e63;
}

.invalidcolor .asterix {
    color: #ff0000;
}

.invalidcolor .labelText {
    color: #ff0000;
}

.invalidcolor textarea {
    border-color: #ff0000;
    box-shadow: none !important;
}

.invalidcolor input {
    border-color: #ff0000;
    box-shadow: none !important;
}

@media(min-width:800px) {
    .rightalign {
        float: right;
    }

    div.w8 {
        display: inline-block;
        width: 8%;
        vertical-align: top;
    }

    div.w5 {
        display: inline-block;
        width: 5%;
        vertical-align: top;
    }

    div.w3 {
        display: inline-block;
        width: 3%;
        vertical-align: top;
    }


    div.w10 {
        display: inline-block;
        width: 10%;
        vertical-align: top;
    }

    div.w16 {
        display: inline-block;
        width: 16%;
        vertical-align: top;
    }

    div.w20 {
        display: inline-block;
        width: 18%;
        vertical-align: top;
    }

    div.w80 {
        display: inline-block;
        width: 80%;
        vertical-align: top;
    }

    div.w50 {
        display: inline-block;
        width: 48%;
        vertical-align: top;
    }

    div.w100 {
        display: inline-block;
        width: 100%;
        vertical-align: top;
    }

    div.w45 {
        display: inline-block;
        width: 45%;
        vertical-align: top;
    }

    div.w40 {
        display: inline-block;
        width: 40%;
        vertical-align: top;
    }

    div.w98 {
        display: inline-block;
        width: 98%;
        vertical-align: top;
    }

    div.w90 {
        display: inline-block;
        width: 90%;
        vertical-align: top;
    }

    div.w85 {
        display: inline-block;
        width: 85%;
        vertical-align: top;
    }

    div.w86 {
        display: inline-block;
        width: 85.5%;
        vertical-align: top;
    }

    div.w33 {
        display: inline-block;
        width: 31%;
        vertical-align: top;
    }
}

@media(max-width:800px) {

    div.component,
    div.w40,
    div.w50,
    div.w45,
    div.w100,
    div.w90,
    div.w98 {
        width: 100% !important;
    }
}

.noteStyle {
    background-color: #eee;
    height: 40px;
    padding: 10px 10px 10px 20px;
    border-radius: 5px;
}

body {
    font-size: 16px;
    color: #0f3e63;
    font-family: "Helvetica", sans-serif;
    background: #eee;
}

.errorText {
    color: #DF0101;
    font-style: italic;
    font-size: 16px;
    font: "Helvetica", sans-serif;
}

.infoText {
    color: #1aa2bc;
    font-style: italic;
    font-size: 16px;
    font: "Helvetica", sans-serif;
}

.closePopupBtn {
    font-family: Lato !important;
    font-size: 14px;
    color: #0f3e63 !important;
    border-radius: 5px;
    outline: 0;
    border-width: 1px;
    border-style: solid;
    background-color: #fff;
    border-color: #337ab7;
}

.eraBtn {
    font-family: Lato !important;
    font-size: 14px;
    color: #0f3e63 !important;
    border-radius: 5px;
    outline: 0;
    border-width: 1px;
    border-style: solid;
    background-color: #fff;
    border-color: #337ab7;
}

.closePopupBtn:hover {
    background-color: #dddddd;
}

button.closePopupBtn:hover {
    background-color: #dddddd !important;
}

button {
    border: 1px solid #337ab7 !important;
    border-radius: 4px;
    color: #0f3e63 !important;
    font-family: "Lato";
    background-color: #fff !important;
}

button:hover {
    text-shadow: 0px 0px 0px 1px rgba(0, 0, 0, 0.2);
}

button#deleteButton:disabled {
    border: 1px solid #888 !important;
    color: #888 !important;
    background-color: #eee !important;
}

button#managePermissionsButton:disabled {
    border: 1px solid #888 !important;
    color: #888 !important;
    background-color: #eee !important;
    cursor: not-allowed;
}

button#saveShellButton:disabled {
    border: 1px solid #888 !important;
    color: #888 !important;
    background-color: #eee !important;
}

button#newEngagementButton:disabled {
    border: 1px solid #888 !important;
    color: #888 !important;
    background-color: #eee !important;
}


button#deleteButton:disabled:hover {
    text-shadow: none !important;
}

button:disabled:hover {
    text-shadow: none !important;
}

button:disabled {
    opacity: 0.3;
    cursor: default;
}

.button-dark{
    background-color: #004068 !important;
    color: #fff !important;
    font-family: "Lato";
    border-radius: 4px;

    :disabled {
        background-color: #a1a1a1;
    }
}

textarea {
    resize: none
}

.modal-permission {
    width: 1204px;
}

.modal-screen-height {
    height: 860px;
}

.modal-pkic-uploadFiles {
    width: 704px;
}

.modal-uploaddocuments {
    width: 500px;
}

.modal-content {
    border-radius: 20px !important;
}

.circle-stroke-zero path {
    stroke: transparent;
}

.modal-pt {
    width: 1250px;
}


@media (max-width: 767px) {
    .modal-missingaccount {
        width: 98%;
        padding: 10px 0px 10px 10px;
    }

    .modal-dialog {
        margin: 0px;
    }
}

@media (min-width: 768px) {
    .modal-missingaccount {
        width: 800px;
    }
}

.dx-datagrid-rowsview .dx-master-detail-row:not(.dx-datagrid-edit-form)>.dx-datagrid-group-space,
.dx-datagrid-rowsview .dx-master-detail-row:not(.dx-datagrid-edit-form) .dx-master-detail-cell {
    background-color: white;
}

.loader {
    font-size: 10px;
    margin: 50px auto;
    text-indent: -9999em;
    width: 11em;
    height: 11em;
    border-radius: 50%;
    background: #0dc5c1;
    background: -moz-linear-gradient(left, #0dc5c1 10%, rgba(13, 197, 193, 0) 42%);
    background: -webkit-linear-gradient(left, #0dc5c1 10%, rgba(13, 197, 193, 0) 42%);
    background: -o-linear-gradient(left, #0dc5c1 10%, rgba(13, 197, 193, 0) 42%);
    background: -ms-linear-gradient(left, #0dc5c1 10%, rgba(13, 197, 193, 0) 42%);
    background: linear-gradient(to right, #0dc5c1 10%, rgba(13, 197, 193, 0) 42%);
    position: relative;
    -webkit-animation: load3 1.4s infinite linear;
    animation: load3 1.4s infinite linear;
    -webkit-transform: translateZ(0);
    -ms-transform: translateZ(0);
    transform: translateZ(0);
}

.loader:before {
    width: 50%;
    height: 50%;
    background: #0dc5c1;
    border-radius: 100% 0 0 0;
    position: absolute;
    top: 0;
    left: 0;
    content: '';
}

.loader:after {
    background: #eee;
    width: 75%;
    height: 75%;
    border-radius: 50%;
    content: '';
    margin: auto;
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
}

@-webkit-keyframes load3 {
    0% {
        -webkit-transform: rotate(0deg);
        transform: rotate(0deg);
    }

    100% {
        -webkit-transform: rotate(360deg);
        transform: rotate(360deg);
    }
}

@keyframes load3 {
    0% {
        -webkit-transform: rotate(0deg);
        transform: rotate(0deg);
    }

    100% {
        -webkit-transform: rotate(360deg);
        transform: rotate(360deg);
    }
}

.picklist-input {
    display: inline-block;
}

.invalid-border {
    border: 1px solid #ff0000 !important;
}

.invalid-message {
    color: #DF0101;
    font-size: 14px;
    font-weight: bold;
    font-style: italic;
}

.warning-border {
    border-color: #f68720;
}

.warning-message {
    color: #f68720;
    font-size: 14px;
    font-weight: bold;
    font-style: italic;
}

.mb-2 {
    margin-bottom: 2em;
}

.mb-1 {
    margin-bottom: 1em;
}

.mr-2 {
    margin-right: 2em;
}

.mr-1 {
    margin-right: 1em;
}

.mt-1 {
    margin-top: 1em;
}

.mt-3 {
    margin-top: 3em;
}

.mt-4 {
    margin-top: 4em;
}

.float-right {
    float: right;
}

.float-left {
    float: left;
}

.w-100 {
    width: 100%;
}

.height-34, .h-34 {
    height: 34px;
}
